/**
 * @generated SignedSource<<23d1c2848927b2dbc1d9ac15a3f948ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuRadioSelectSearch_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuTypeaheadContainer_viewer">;
  readonly " $fragmentType": "SbSharedRefineMenuRadioSelectSearch_viewer";
};
export type SbSharedRefineMenuRadioSelectSearch_viewer$key = {
  readonly " $data"?: SbSharedRefineMenuRadioSelectSearch_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuRadioSelectSearch_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuRadioSelectSearch_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuTypeaheadContainer_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "7f4ab4b9bb82a100a696e1ed031ed2e1";

export default node;
