/**
 * @generated SignedSource<<544a650ab3ca824bedcf467f3ff6f618>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespRefineMenuFilterPrice_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuInputRangeCurrency_itemSearch" | "SbSharedRefineMenuNetPriceTradeDiscount_itemSearch" | "SbSharedRefineMenuPriceRanges_itemSearch" | "SbSharedRefineMenuSaleInput_itemSearch">;
  readonly " $fragmentType": "SbRespRefineMenuFilterPrice_itemSearch";
};
export type SbRespRefineMenuFilterPrice_itemSearch$key = {
  readonly " $data"?: SbRespRefineMenuFilterPrice_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenuFilterPrice_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespRefineMenuFilterPrice_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuInputRangeCurrency_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuPriceRanges_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuSaleInput_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuNetPriceTradeDiscount_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "0728b8ed3a0877b23c95848f3869ce7d";

export default node;
