/**
 * @generated SignedSource<<01d4e8146840d5382f07165943e0b3d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sellerBrandingHelpers_user$data = {
  readonly tradeFirm: {
    readonly rewards: {
      readonly currentProgram: {
        readonly programInfo: {
          readonly code: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly " $fragmentType": "sellerBrandingHelpers_user";
};
export type sellerBrandingHelpers_user$key = {
  readonly " $data"?: sellerBrandingHelpers_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"sellerBrandingHelpers_user">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "sellerBrandingHelpers_user"
};

(node as any).hash = "6ed9038093153e22e223858e2e17069a";

export default node;
