/**
 * @generated SignedSource<<e425d7392296421d7cae8075f1f298b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedHeadScriptTags_metadata$data = {
  readonly microdata: string | null;
  readonly " $fragmentType": "SharedHeadScriptTags_metadata";
};
export type SharedHeadScriptTags_metadata$key = {
  readonly " $data"?: SharedHeadScriptTags_metadata$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedHeadScriptTags_metadata">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedHeadScriptTags_metadata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "microdata",
      "storageKey": null
    }
  ],
  "type": "MetaData",
  "abstractKey": null
};

(node as any).hash = "b1b4576dd4c2a4a6bbd5990cb11c9cf0";

export default node;
