import { type ElementType } from 'react';
import { defineMessages, type MessageDescriptor } from 'dibs-react-intl';

// components
import { SbRespRefineMenuFilterPrice } from './SbRespRefineMenuFilterPrice';
import { SbRespRefineMenuFilterCategory } from './SbRespRefineMenuFilterCategory';
import { SbRespRefineMenuFilterStoneCut } from './SbRespRefineMenuFilterStoneCut';
import { SbRespRefineMenuFilterDimensions } from './SbRespRefineMenuFilterDimensions';
import { SbRespRefineMenuFilterCaratWeight } from './SbRespRefineMenuFilterCaratWeight';
import { SbRespRefineMenuFilterArtSize } from './SbRespRefineMenuFilterArtSize';
import { SbSharedRefineMenuFilterItemLocation } from '../../SbSharedRefineMenu/SbSharedRefineMenuFilters/SbSharedRefineMenuFilterItemLocation';
import { SbSharedRefineMenuFilterDeliveryOptions } from '../../SbSharedRefineMenu/SbSharedRefineMenuFilters/SbSharedRefineMenuFilterDeliveryOptions';
import { sbSharedRefineMenuFiltersMap } from '../../SbSharedRefineMenu/SbSharedRefineMenuFilters/sbSharedRefineMenuFiltersMap';

const titleOverrides = defineMessages({
    dimensions: {
        id: 'sb.SbRespRefineMenuFilterDimension.title',
        defaultMessage: 'Dimensions',
    },
    shippingOptions: {
        id: 'sb.SbRespRefineMenuFilterDeliveryOptions.title',
        defaultMessage: 'Shipping Options',
    },
    itemLocation: {
        id: 'sb.SbRespRefineMenuFilterItemLocation.title',
        defaultMessage: 'Item Location',
    },
});

type FilterConfigType = {
    component?: ElementType | null;
    title?: MessageDescriptor;
    isBatchAppliedFilter?: boolean;
};

export const sbRespRefineMenuFiltersMap: Record<string | symbol, FilterConfigType> = {
    ...sbSharedRefineMenuFiltersMap,
    categoryL1: {
        component: SbRespRefineMenuFilterCategory,
    },
    'carat-weight': {
        component: SbRespRefineMenuFilterCaratWeight,
    },
    'art-size': {
        component: SbRespRefineMenuFilterArtSize,
    },
    height: {
        component: SbRespRefineMenuFilterDimensions,
        title: titleOverrides.dimensions,
    },
    width: {
        component: SbRespRefineMenuFilterDimensions,
        title: titleOverrides.dimensions,
    },
    length: {
        component: SbRespRefineMenuFilterDimensions,
        title: titleOverrides.dimensions,
    },
    depth: {
        component: SbRespRefineMenuFilterDimensions,
        title: titleOverrides.dimensions,
    },
    price: {
        component: SbRespRefineMenuFilterPrice,
    },
    priceTrade: {
        component: SbRespRefineMenuFilterPrice,
    },
    'stone-cut': {
        component: SbRespRefineMenuFilterStoneCut,
    },
    latest: {
        component: null,
        isBatchAppliedFilter: true,
    },
    shippingOptions: {
        component: SbSharedRefineMenuFilterDeliveryOptions,
        title: titleOverrides.shippingOptions,
    },
    location: {
        component: SbSharedRefineMenuFilterItemLocation,
        title: titleOverrides.itemLocation,
    },
    sale: {
        component: null,
        isBatchAppliedFilter: true,
    },
    'new-arrivals': {
        component: null,
        isBatchAppliedFilter: true,
    },
    customizable: {
        component: null,
        isBatchAppliedFilter: true,
    },
    'net-price': {
        component: null,
        isBatchAppliedFilter: true,
    },
    returnable: {
        component: null,
        isBatchAppliedFilter: true,
    },
    recognized: {
        component: null,
        isBatchAppliedFilter: true,
    },
    topSeller: {
        component: null,
        isBatchAppliedFilter: true,
    },
    isSellerStrategicPartner: {
        component: null,
        isBatchAppliedFilter: true,
    },
    view: {
        component: null,
        isBatchAppliedFilter: true,
    },
};

export const isBatchAppliedFilter = (filterName: string): boolean =>
    sbRespRefineMenuFiltersMap[filterName] &&
    !!sbRespRefineMenuFiltersMap[filterName]?.isBatchAppliedFilter;
