/**
 * @generated SignedSource<<f996197dd4f58756c5be190ed9ea839b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuInputRangeDimensions_filters$data = ReadonlyArray<{
  readonly localizedFilterName: string | null;
  readonly name: string | null;
  readonly values: ReadonlyArray<{
    readonly linkReference: string | null;
    readonly stats: ReadonlyArray<{
      readonly key: string | null;
      readonly values: string | null;
    } | null> | null;
    readonly urlLabel: string | null;
  } | null> | null;
  readonly " $fragmentType": "SbSharedRefineMenuInputRangeDimensions_filters";
}>;
export type SbSharedRefineMenuInputRangeDimensions_filters$key = ReadonlyArray<{
  readonly " $data"?: SbSharedRefineMenuInputRangeDimensions_filters$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuInputRangeDimensions_filters">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbSharedRefineMenuInputRangeDimensions_filters",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedFilterName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseFilterValue",
      "kind": "LinkedField",
      "name": "values",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkReference",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "stats",
          "kind": "LinkedField",
          "name": "stats",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "values",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SearchBrowseFilter",
  "abstractKey": null
};

(node as any).hash = "0e253980c877283c7bea8f579cbfb3ed";

export default node;
