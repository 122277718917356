/**
 * @generated SignedSource<<59908e73f7a36a9888066e53fc2a3792>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterMaterial_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuMultiSelectSearch_viewer">;
  readonly " $fragmentType": "SbSharedRefineMenuFilterMaterial_viewer";
};
export type SbSharedRefineMenuFilterMaterial_viewer$key = {
  readonly " $data"?: SbSharedRefineMenuFilterMaterial_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterMaterial_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilterMaterial_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuMultiSelectSearch_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "f1ee0f61f00d2c93120f509f2ef54cde";

export default node;
