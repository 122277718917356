import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import Helmet from 'react-helmet';

import { type SharedHeadScriptTags_metadata$data } from './__generated__/SharedHeadScriptTags_metadata.graphql';

type Props = { metadata: SharedHeadScriptTags_metadata$data };

const SharedHeadScriptTagsComponent: FC<Props> = ({ metadata }) => {
    const scriptTagsArray = [];

    const { microdata } = metadata;
    if (microdata) {
        scriptTagsArray.push({
            type: 'application/ld+json',
            innerHTML: microdata,
        });
    }

    return <Helmet script={scriptTagsArray} />;
};

export const SharedHeadScriptTags = createFragmentContainer(SharedHeadScriptTagsComponent, {
    metadata: graphql`
        fragment SharedHeadScriptTags_metadata on MetaData {
            microdata
        }
    `,
});
