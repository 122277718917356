/**
 * @generated SignedSource<<6ac0055e579c159e619990dd105d4b1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuTypeahead_viewer$data = {
  readonly itemFacetSearch?: {
    readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuSearchResults_itemFacetSearch">;
  } | null;
  readonly " $fragmentType": "SbSharedRefineMenuTypeahead_viewer";
};
export type SbSharedRefineMenuTypeahead_viewer$key = {
  readonly " $data"?: SbSharedRefineMenuTypeahead_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuTypeahead_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "facetName"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "hasQuery"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchString"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "uriRef"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "userCountryCode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuTypeahead_viewer",
  "selections": [
    {
      "condition": "hasQuery",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "countryCode",
              "variableName": "userCountryCode"
            },
            {
              "kind": "Variable",
              "name": "facetName",
              "variableName": "facetName"
            },
            {
              "kind": "Variable",
              "name": "searchString",
              "variableName": "searchString"
            },
            {
              "kind": "Variable",
              "name": "uriRef",
              "variableName": "uriRef"
            }
          ],
          "concreteType": "ItemFacetsQueryConnection",
          "kind": "LinkedField",
          "name": "itemFacetSearch",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SbSharedRefineMenuSearchResults_itemFacetSearch"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "54aa0919b0a79e93703dfd3f9ec36581";

export default node;
