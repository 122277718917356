/**
 * @generated SignedSource<<1b306af7bc81f12163cb4e42711dbdef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedHeadMetaTags_metadata$data = {
  readonly canonical: string | null;
  readonly description: string | null;
  readonly disableIndexing: boolean | null;
  readonly title: string | null;
  readonly " $fragmentType": "SharedHeadMetaTags_metadata";
};
export type SharedHeadMetaTags_metadata$key = {
  readonly " $data"?: SharedHeadMetaTags_metadata$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedHeadMetaTags_metadata">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedHeadMetaTags_metadata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canonical",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disableIndexing",
      "storageKey": null
    }
  ],
  "type": "MetaData",
  "abstractKey": null
};

(node as any).hash = "ca76300d3b6c58791ea89756c863589a";

export default node;
