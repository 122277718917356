import { useState, type FC, useCallback, type ReactElement } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import { trackFollowButtonClick } from '../../utils/tracking/searchBrowse/trackFollowSearch';
import { authLabels, locations } from '../../utils/tracking/shared/followSearchTracking';
import { pageTypeConstants as PAGE_TYPES } from '../../constants/pageTypeConstants';
import {
    checkRewardedSeller,
    checkTradeUserUnderTopLevelLoyaltyTiers,
} from 'dibs-buyer-layout/exports/sellerBrandingHelpers';
import { isSellerBrandingRemovalTestVariant } from 'dibs-buyer-layout/exports/sellerBrandingRemovalAbTestHelpers';

// Components
import { SaveSearchButton } from '../../components/global/SaveSearchButton/SaveSearchButton';
import { TooltipLoader } from '../../global/TooltipLoader';
import { FormattedMessage } from 'dibs-react-intl';
import { useSharedSaveSearchContext } from '../SharedSaveSearchContext/SharedSaveSearchContext';

import { type SharedFollowTopButton_itemSearch$key } from './__generated__/SharedFollowTopButton_itemSearch.graphql';
import { type SharedFollowTopButton_user$key } from './__generated__/SharedFollowTopButton_user.graphql';

import dibsCss from 'dibs-css';
import { useSbSelector } from '../../reducers/useSbSelector';

const itemSearchFragment = graphql`
    fragment SharedFollowTopButton_itemSearch on ItemSearchQueryConnection {
        ...SaveSearchButton_itemSearch
        pageType
        seller {
            ...sellerBrandingHelpers_seller
        }
    }
`;
const userFragment = graphql`
    fragment SharedFollowTopButton_user on User {
        ...sellerBrandingHelpers_user
    }
`;

type Props = {
    itemSearch: SharedFollowTopButton_itemSearch$key | null;
    user: SharedFollowTopButton_user$key | null;
    addLeadingSpace?: boolean;
};

export const SharedFollowTopButton: FC<Props> = ({
    itemSearch: itemSearchRef,
    user: userRef,
    addLeadingSpace,
}) => {
    const [loadHoverTooltip, setLoadHoverTooltip] = useState(false);
    const [loadSuccessTooltip, setLoadSuccessTooltip] = useState(false);
    const [showHoverTooltip, setShowHoverTooltip] = useState(false);
    const [showSuccessTooltip, setShowSuccessTooltip] = useState(false);

    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const user = useFragment(userFragment, userRef);

    const pageType = itemSearch?.pageType || '';
    const isClient = useSbSelector(store => store.relayVariables.variables.isClient);
    const { isCategorySearch, isFollowing, followSearchValue, showFollowButton } =
        useSharedSaveSearchContext();

    const handleFollowSearchCompleted = useCallback(
        (pageIsFollowed: boolean): void => {
            if (pageIsFollowed) {
                // show success tooltip is user follows a search (but not if they unfollow)
                setLoadSuccessTooltip(true);
                setShowSuccessTooltip(true);
                setShowHoverTooltip(false);
                const timer = setTimeout(() => {
                    setShowSuccessTooltip(false);
                    clearTimeout(timer);
                }, 3000);
            }
            trackFollowButtonClick({
                isFollowing: pageIsFollowed,
                label: pageType === PAGE_TYPES.BUY ? 'top of buy page' : 'top of results',
            });
        },
        [pageType]
    );

    if (!showFollowButton) {
        return null;
    }

    // only show hover tooltip if user is not following search
    const mouseProps = {
        onMouseEnter: () => {
            setShowHoverTooltip(!isFollowing);
            setLoadHoverTooltip(!isFollowing);
        },
        onMouseLeave: () => {
            setShowHoverTooltip(false);
        },
    };

    const getSuccessToolTipContent = (): ReactElement => {
        if (
            isClient &&
            (pageType === PAGE_TYPES.MORE_FROM_SELLER || pageType === PAGE_TYPES.DEALER)
        ) {
            const shouldShowSellerName =
                !isSellerBrandingRemovalTestVariant() ||
                checkRewardedSeller(itemSearch?.seller || null) ||
                checkTradeUserUnderTopLevelLoyaltyTiers(user || null);
            if (!shouldShowSellerName) {
                return (
                    <FormattedMessage
                        id="sb.SharedFollowTopButton.successTextHiddenSeller"
                        defaultMessage="Check your email for weekly updates."
                    />
                );
            }
        }

        return (
            <FormattedMessage
                id="sb.SharedFollowTopButton.successText"
                defaultMessage="Check your email for weekly updates in {name}."
                values={{
                    name: (
                        <span
                            data-tn="follow-search-value"
                            className={dibsCss.sassyFontBodyTypeHeavySizeNone}
                        >
                            {isCategorySearch ? followSearchValue : `"${followSearchValue}"`}
                        </span>
                    ),
                }}
            />
        );
    };

    return (
        <div
            {...mouseProps}
            className={classnames(dibsCss.flexShrink0, dibsCss.relative, {
                [dibsCss.mlSmall]: addLeadingSpace,
            })}
            data-tn="top-save-search-button"
        >
            <SaveSearchButton
                itemSearch={itemSearch}
                locationLabel={authLabels[locations.SEARCH_BROWSE]}
                onComplete={handleFollowSearchCompleted}
                size="medium"
            />
            <TooltipLoader
                load={loadHoverTooltip}
                isVisible={showHoverTooltip}
                closeOnOutsideClick
                direction="right"
            >
                <FormattedMessage
                    id="sb.SharedFollowTopButton.hoverText"
                    defaultMessage="Get weekly updates with new arrivals for this category."
                />
            </TooltipLoader>
            <TooltipLoader
                load={loadSuccessTooltip}
                isVisible={showSuccessTooltip}
                closeOnOutsideClick
                direction="right"
            >
                {getSuccessToolTipContent()}
            </TooltipLoader>
        </div>
    );
};
