/**
 * @generated SignedSource<<83f774dbeccc3406409a423bbcb19a1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespRefineMenuFilterDimensions_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuInputRangeDimensions_itemSearch">;
  readonly " $fragmentType": "SbRespRefineMenuFilterDimensions_itemSearch";
};
export type SbRespRefineMenuFilterDimensions_itemSearch$key = {
  readonly " $data"?: SbRespRefineMenuFilterDimensions_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenuFilterDimensions_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespRefineMenuFilterDimensions_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuInputRangeDimensions_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "5e1268eefe3c5705cbcbd04f966defe2";

export default node;
