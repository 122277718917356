/**
 * @generated SignedSource<<62a26d1203d6af3a561eddaaaf63a592>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterRingSize_filters$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuRingSize_filters">;
  readonly " $fragmentType": "SbSharedRefineMenuFilterRingSize_filters";
}>;
export type SbSharedRefineMenuFilterRingSize_filters$key = ReadonlyArray<{
  readonly " $data"?: SbSharedRefineMenuFilterRingSize_filters$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterRingSize_filters">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbSharedRefineMenuFilterRingSize_filters",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuRingSize_filters"
    }
  ],
  "type": "SearchBrowseFilter",
  "abstractKey": null
};

(node as any).hash = "f6c8a113cbc6b047cf0490eb6c3ada66";

export default node;
