/**
 * @generated SignedSource<<a1b21ac4c7c686fc3f6952bb999d0456>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterStone_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuMultiSelectSearch_itemSearch">;
  readonly " $fragmentType": "SbSharedRefineMenuFilterStone_itemSearch";
};
export type SbSharedRefineMenuFilterStone_itemSearch$key = {
  readonly " $data"?: SbSharedRefineMenuFilterStone_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterStone_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilterStone_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuMultiSelectSearch_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "81fc1403c4474ba95e83c72409ee2007";

export default node;
