/**
 * @generated SignedSource<<1bf7fa5453ba42376a184f0e4534ce14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterArtSize_filters$data = ReadonlyArray<{
  readonly localizedFilterName: string | null;
  readonly name: string | null;
  readonly values: ReadonlyArray<{
    readonly linkReference: string | null;
    readonly urlLabel: string | null;
  } | null> | null;
  readonly " $fragmentType": "SbSharedRefineMenuFilterArtSize_filters";
}>;
export type SbSharedRefineMenuFilterArtSize_filters$key = ReadonlyArray<{
  readonly " $data"?: SbSharedRefineMenuFilterArtSize_filters$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterArtSize_filters">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbSharedRefineMenuFilterArtSize_filters",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedFilterName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseFilterValue",
      "kind": "LinkedField",
      "name": "values",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkReference",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SearchBrowseFilter",
  "abstractKey": null
};

(node as any).hash = "38d027fe6dddbb9740ef7049a3f60e73";

export default node;
