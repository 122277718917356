import { graphql, readInlineData } from 'react-relay';
import { type sellerBrandingHelpers_seller$key } from './__generated__/sellerBrandingHelpers_seller.graphql';
import { type sellerBrandingHelpers_user$key } from './__generated__/sellerBrandingHelpers_user.graphql';

const sellerFragment = graphql`
    fragment sellerBrandingHelpers_seller on Seller @inline {
        isRewarded: isAnchorListsMember(listNames: ["SELLER_REWARDS_1", "SELLER_REWARDS_2"])
    }
`;

const userFragment = graphql`
    fragment sellerBrandingHelpers_user on User @inline {
        tradeFirm {
            rewards {
                currentProgram {
                    programInfo {
                        code
                    }
                }
            }
        }
    }
`;

export const checkRewardedSeller = (
    sellerRef: sellerBrandingHelpers_seller$key | null | undefined
): boolean => {
    const seller = readInlineData(sellerFragment, sellerRef);

    return !!seller?.isRewarded;
};

export const checkTradeUserUnderLevelLoyaltyTiers = (
    userRef: sellerBrandingHelpers_user$key | null | undefined,
    programCodes: string[] = []
): boolean => {
    const user = readInlineData(userFragment, userRef);

    return programCodes.includes(user?.tradeFirm?.rewards?.currentProgram?.programInfo?.code || '');
};

export const checkTradeUserUnderTopLevelLoyaltyTiers = (
    user: sellerBrandingHelpers_user$key | null | undefined
): boolean => checkTradeUserUnderLevelLoyaltyTiers(user, ['GOLD', 'PLATINUM']);

export const isSellerPage = (uri: string = ''): boolean => {
    return uri.startsWith('dealers', 1) || uri.startsWith('more-from-seller', 1);
};

export const changeSellerStorefrontUrlToShop = (uri: string = ''): string => {
    const [path, searchParams] = uri.split('?');
    // storefront path "/dealers/dealer-name/" would split to exactly 4 array elements
    if (uri.startsWith('dealers', 1) && path.split('/').length === 4) {
        // sort by newest in the shop
        const params = new URLSearchParams(searchParams);
        params.set('sort', 'newest');
        return `${path}shop/?${params.toString()}`;
    }
    return uri;
};
