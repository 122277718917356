import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { getFilterValues, getAppliedFilter } from '../sbSharedRefineMenuHelpers';

// components
import { SbSharedRefineMenuRadioSelect } from './SbSharedRefineMenuRadioSelect';
import { SbSharedRefineMenuExpandableItemList } from '../SbSharedRefineMenuExpandableItemList/SbSharedRefineMenuExpandableItemList';

/* eslint-disable react/prop-types */

export const SbSharedRefineMenuRadioSelectListComponent = props => {
    const {
        values,
        allLabel,
        filterName,
        totalCount,
        itemSearch,
        notDismissable,
        collapsedItemCount,
        shouldUseExpandableList = true,
        showAllOption,
    } = props;
    const { appliedFilters } = itemSearch;

    const appliedFilter = getAppliedFilter(appliedFilters, filterName);
    const appliedFilterValues = getFilterValues(appliedFilters, filterName);
    let canBeDismissed;

    if (notDismissable !== undefined) {
        canBeDismissed = notDismissable;
    } else {
        canBeDismissed = appliedFilter ? appliedFilter.canBeDismissed : true;
    }

    if (!shouldUseExpandableList) {
        return (
            <SbSharedRefineMenuRadioSelect
                allLabel={allLabel}
                totalCount={totalCount}
                filterName={filterName}
                valuesToShow={values}
                canBeDismissed={canBeDismissed}
                appliedFilterValues={appliedFilterValues}
            />
        );
    }

    return (
        <SbSharedRefineMenuExpandableItemList
            filterName={filterName}
            availableFilterValues={values}
            collapsedItemCount={collapsedItemCount}
        >
            {({ valuesToShow }) => (
                <SbSharedRefineMenuRadioSelect
                    allLabel={allLabel}
                    totalCount={totalCount}
                    filterName={filterName}
                    valuesToShow={valuesToShow}
                    canBeDismissed={canBeDismissed}
                    appliedFilterValues={appliedFilterValues}
                    showAllOption={showAllOption}
                />
            )}
        </SbSharedRefineMenuExpandableItemList>
    );
};

SbSharedRefineMenuRadioSelectListComponent.propTypes = {
    allLabel: PropTypes.string,
    values: PropTypes.array.isRequired,
    totalCount: PropTypes.number,
    notDismissable: PropTypes.bool,
    collapsedItemCount: PropTypes.number,
    filterName: PropTypes.string.isRequired,
    itemSearch: PropTypes.object.isRequired,
    shouldUseExpandableList: PropTypes.bool,
    showAllOption: PropTypes.bool,
};

export const SbSharedRefineMenuRadioSelectList = createFragmentContainer(
    SbSharedRefineMenuRadioSelectListComponent,
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuRadioSelectList_itemSearch on ItemSearchQueryConnection {
                appliedFilters {
                    name
                    localizedFilterName
                    canBeDismissed
                    values {
                        urlLabel
                        linkReference
                        count
                        displayName
                    }
                }
            }
        `,
    }
);
