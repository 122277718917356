/**
 * @generated SignedSource<<933ac1a5a608c67d4bf1e32e1be8af4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterCreator_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuMultiSelectSearch_viewer">;
  readonly " $fragmentType": "SbSharedRefineMenuFilterCreator_viewer";
};
export type SbSharedRefineMenuFilterCreator_viewer$key = {
  readonly " $data"?: SbSharedRefineMenuFilterCreator_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterCreator_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilterCreator_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuMultiSelectSearch_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "0e18e5187b12e17e380593bb93529af4";

export default node;
