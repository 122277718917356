import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { getFilterValues } from '../sbSharedRefineMenuHelpers';
import { updateUriRef } from '../../../actions/filterActions';
import { AbsoluteAnchor } from '../../../global/AbsoluteAnchor/AbsoluteAnchor';

// styles
import style from './SbSharedRefineMenuColorSelect.scss';

export const SbSharedRefineMenuColorSelectComponent = props => {
    const { filter, onChange = () => {}, itemSearch, filterName } = props;
    const { appliedFilters } = itemSearch;
    const appliedFilterValues = getFilterValues(appliedFilters, filterName);
    const selected = appliedFilterValues.map(color => color.urlLabel);
    const refetchInFlight = useSelector(state => state.filters.refetchInFlight);

    return (
        <div className={style.container}>
            {filter.values.map((color, idx) => {
                const isSelected = selected.includes(color.urlLabel);

                return (
                    <div
                        key={`${color.displayName}-${idx}`}
                        className={style.colorSelect}
                        data-color={color.displayName}
                        data-tn={`color-${color.displayName}`.toLowerCase()}
                    >
                        <AbsoluteAnchor
                            linkReference={color.linkReference}
                            linkable={color.linkable}
                            title={color.displayName}
                            onClick={e => e.preventDefault()}
                            inert // onClick makes link inert, this prop makes it fully inert
                        >
                            <input
                                aria-label={color.displayName}
                                className={classnames(style.swatch, {
                                    [style.selected]: isSelected,
                                    [style.disabled]: refetchInFlight,
                                })}
                                style={{ backgroundColor: color.hexCode }}
                                checked={isSelected}
                                type="checkbox"
                                onChange={e => onChange(color, isSelected, e)}
                            />
                        </AbsoluteAnchor>
                    </div>
                );
            })}
        </div>
    );
};

SbSharedRefineMenuColorSelectComponent.propTypes = {
    filter: PropTypes.object.isRequired,
    itemSearch: PropTypes.object.isRequired,
    filterName: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

function mapDispatchToProps(dispatch, { filterName }) {
    return {
        onChange(filterValue, isDeselected, event) {
            dispatch(updateUriRef({ filterName, filterValue, isDeselected, event }));
        },
    };
}

export const SbSharedRefineMenuColorSelect = createFragmentContainer(
    connect(null, mapDispatchToProps)(SbSharedRefineMenuColorSelectComponent),
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuColorSelect_itemSearch on ItemSearchQueryConnection {
                appliedFilters {
                    name
                    values {
                        urlLabel
                    }
                }
            }
        `,
    }
);
