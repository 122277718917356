import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUriRef } from '../../../actions/filterActions';

// components
import { SbSharedRefineMenuLink } from '../../SbSharedRefineMenu/SbSharedRefineMenuLink/SbSharedRefineMenuLink';
import { SbSharedRefineMenuExpandableItemList } from '../../SbSharedRefineMenu/SbSharedRefineMenuExpandableItemList/SbSharedRefineMenuExpandableItemList';

export const SbRespRefineMenuLinkListComponent = props => {
    const {
        filterName,
        values,
        onClick = () => {},
        shouldIndent,
        shouldUseExpandableList = true,
    } = props;

    if (!shouldUseExpandableList) {
        return values.map(value => {
            const { displayName, count, linkReference, linkable, isHidden } = value;
            return (
                <SbSharedRefineMenuLink
                    onClick={e => {
                        e.preventDefault();
                        onClick(filterName, value, e);
                    }}
                    count={count}
                    linkReference={linkReference}
                    linkable={linkable}
                    key={displayName}
                    text={displayName}
                    shouldIndent={shouldIndent}
                    isHidden={isHidden}
                />
            );
        });
    }

    return (
        <SbSharedRefineMenuExpandableItemList
            filterName={filterName}
            availableFilterValues={values}
        >
            {({ valuesToShow }) =>
                valuesToShow.map(value => {
                    const { displayName, count, linkReference, linkable, isHidden } = value;
                    return (
                        <SbSharedRefineMenuLink
                            onClick={e => {
                                e.preventDefault();
                                onClick(filterName, value, e);
                            }}
                            count={count}
                            linkReference={linkReference}
                            linkable={linkable}
                            key={displayName}
                            text={displayName}
                            shouldIndent={shouldIndent}
                            isHidden={isHidden}
                        />
                    );
                })
            }
        </SbSharedRefineMenuExpandableItemList>
    );
};

SbRespRefineMenuLinkListComponent.propTypes = {
    onClick: PropTypes.func,
    shouldIndent: PropTypes.bool,
    values: PropTypes.array.isRequired,
    filterName: PropTypes.string.isRequired,
    shouldUseExpandableList: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
    return {
        onClick(filterName, filterValue, event) {
            dispatch(updateUriRef({ filterName, filterValue, event }));
        },
    };
}

export const SbRespRefineMenuLinkList = connect(
    null,
    mapDispatchToProps
)(SbRespRefineMenuLinkListComponent);
