import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { getFilterValues } from '../sbSharedRefineMenuHelpers';
import { DEFAULT_LOCATION_LABELS } from '../sbSharedRefineMenuConstants';
import { useSbSelector } from '../../../reducers/useSbSelector';
// components
import { SbSharedRefineMenuRadioSelectSearch } from '../SbSharedRefineMenuRadioSelect/SbSharedRefineMenuRadioSelectSearch';

const getUserLocationValues = viewer => {
    const locationsByZipCode = viewer?.locations?.edges || [];
    return locationsByZipCode.map(location => {
        const { displayName, urlLabel, count, linkReference } = location?.node || {};
        return {
            displayName,
            urlLabel,
            count,
            linkReference,
        };
    });
};

export const SbSharedRefineMenuFilterItemLocationComponent = props => {
    const {
        values: facetFilterValues,
        itemSearch: { appliedFilters },
        filterName,
        viewer,
    } = props;

    const userZipCode = useSbSelector(state => state.relayVariables.variables?.userZipCode);
    const userLocationValues =
        userZipCode && userZipCode !== '0' ? getUserLocationValues(viewer) : [];

    const appliedFilterValues = getFilterValues(appliedFilters, filterName).map(
        locationAppliedFilterValue => {
            return Object.assign({}, locationAppliedFilterValue, {
                count: facetFilterValues.find(
                    ({ urlLabel }) => locationAppliedFilterValue.urlLabel === urlLabel
                )?.count,
            });
        }
    );

    const validLocationLabels = new Set([
        ...DEFAULT_LOCATION_LABELS,
        ...appliedFilterValues.map(filter => filter.urlLabel),
        ...userLocationValues.map(location => location.urlLabel),
    ]);

    const orderedValues = [
        ...appliedFilterValues,
        ...facetFilterValues,
        ...userLocationValues,
    ].reduce((acc, curr) => {
        const { urlLabel } = curr;
        const isLocationAlreadyExists = acc.find(item => item.urlLabel === urlLabel);
        if (isLocationAlreadyExists) {
            return acc;
        } else if (validLocationLabels.has(curr.urlLabel)) {
            acc.push(curr);
        }
        return acc;
    }, []);

    return <SbSharedRefineMenuRadioSelectSearch {...props} values={orderedValues} />;
};

SbSharedRefineMenuFilterItemLocationComponent.propTypes = {
    values: PropTypes.array.isRequired,
    filterName: PropTypes.string.isRequired,
    itemSearch: PropTypes.object.isRequired,
    viewer: PropTypes.object,
    uriRef: PropTypes.string,
};

export const SbSharedRefineMenuFilterItemLocation = createFragmentContainer(
    SbSharedRefineMenuFilterItemLocationComponent,
    {
        viewer: graphql`
            fragment SbSharedRefineMenuFilterItemLocation_viewer on Viewer {
                ...SbSharedRefineMenuRadioSelectSearch_viewer
                locations: itemFacetSearch(
                    uriRef: $uriRef
                    facetName: "location"
                    facetLimit: 10
                    zipCode: $userZipCode
                    countryCode: $userCountryCode
                ) @include(if: $isClient) {
                    edges {
                        node {
                            count
                            displayName
                            urlLabel
                            linkReference
                        }
                    }
                }
            }
        `,
        itemSearch: graphql`
            fragment SbSharedRefineMenuFilterItemLocation_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuRadioSelectSearch_itemSearch
                appliedFilters {
                    name
                    values {
                        count
                        displayName
                        urlLabel
                        linkable
                        linkReference
                    }
                }
            }
        `,
    }
);
