/**
 * @generated SignedSource<<289cbd49cd748c6849461b6b585b34bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterDeliveryOptions_itemSearch$data = {
  readonly appliedFilters: ReadonlyArray<{
    readonly name: string | null;
  } | null> | null;
  readonly " $fragmentType": "SbSharedRefineMenuFilterDeliveryOptions_itemSearch";
};
export type SbSharedRefineMenuFilterDeliveryOptions_itemSearch$key = {
  readonly " $data"?: SbSharedRefineMenuFilterDeliveryOptions_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterDeliveryOptions_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilterDeliveryOptions_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseParsedParam",
      "kind": "LinkedField",
      "name": "appliedFilters",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "fe27d069d78ec023873cfb65fb35031f";

export default node;
