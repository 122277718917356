import { type ReactNode } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { type ValueOf } from 'dibs-ts-utils/exports/ValueOf';
import { VERTICALS } from '../constants/uriConstants';

export const relatedCreatorsTitle = (vertical: string, creatorName: string): ReactNode => {
    if (vertical === VERTICALS.ART) {
        return (
            <FormattedMessage
                id="sb.shopRelatedArtists"
                defaultMessage="Artists Similar to {creatorName}"
                values={{ creatorName }}
            />
        );
    } else if (vertical === VERTICALS.FASHION) {
        return (
            <FormattedMessage
                id="sb.shopRelatedDesigners"
                defaultMessage="Designers Similar to {creatorName}"
                values={{ creatorName }}
            />
        );
    } else {
        return (
            <FormattedMessage
                id="sb.shopRelatedCreators"
                defaultMessage="Creators Similar to {creatorName}"
                values={{ creatorName }}
            />
        );
    }
};

export const shopAll = (shopAllTitle: string): ReactNode => (
    <FormattedMessage
        id="SbSharedRelatedCreator.shopAll"
        defaultMessage="Shop All {shopAllTitle}"
        values={{ shopAllTitle }}
    />
);

export const shopCreatorAtSeller = ({
    creatorItemCount,
    creator,
}: {
    creatorItemCount: number;
    creator: string;
}): ReactNode => (
    <FormattedMessage
        id="sb.shopCreatorAtSeller"
        defaultMessage="Shop {creatorItemCount} {creator} Items"
        values={{ creatorItemCount, creator }}
    />
);

export const shopVertical: Record<ValueOf<typeof VERTICALS>, ReactNode> = {
    furniture: <FormattedMessage id="sb.shopFurniture" defaultMessage="Shop Furniture" />,
    art: <FormattedMessage id="sb.shopArt" defaultMessage="Shop Art" />,
    jewelry: <FormattedMessage id="sb.shopJewelry" defaultMessage="Shop Jewelry & Watches" />,
    fashion: <FormattedMessage id="sb.shopFashion" defaultMessage="Shop Fashion" />,
};

export const viewMore: ReactNode = <FormattedMessage id="sb.viewMore" defaultMessage="View More" />;

export const viewLess: ReactNode = <FormattedMessage id="sb.viewLess" defaultMessage="View Less" />;
