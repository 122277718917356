/**
 * @generated SignedSource<<4cfd9582ea3f7376457368b6b26fbf70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterColor_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuColorSelect_itemSearch">;
  readonly " $fragmentType": "SbSharedRefineMenuFilterColor_itemSearch";
};
export type SbSharedRefineMenuFilterColor_itemSearch$key = {
  readonly " $data"?: SbSharedRefineMenuFilterColor_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterColor_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilterColor_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuColorSelect_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "1982be8a0d269a8fc9592c9e440e3002";

export default node;
