/**
 * @generated SignedSource<<8975ef09ac29c21d2f6dbe194df5cf86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespRefineMenuFilterPrice_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuPriceRanges_user">;
  readonly " $fragmentType": "SbRespRefineMenuFilterPrice_user";
};
export type SbRespRefineMenuFilterPrice_user$key = {
  readonly " $data"?: SbRespRefineMenuFilterPrice_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenuFilterPrice_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespRefineMenuFilterPrice_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuPriceRanges_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "d5d1849717150a9b7015e0a209e2f85a";

export default node;
