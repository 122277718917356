/**
 * @generated SignedSource<<2350dbcdb0d90de9a4034b4b95253935>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespRefineMenuFilterArtSize_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterArtSize_user">;
  readonly " $fragmentType": "SbRespRefineMenuFilterArtSize_user";
};
export type SbRespRefineMenuFilterArtSize_user$key = {
  readonly " $data"?: SbRespRefineMenuFilterArtSize_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenuFilterArtSize_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespRefineMenuFilterArtSize_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterArtSize_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "2a307994e25ec62a09dace44159c74ae";

export default node;
