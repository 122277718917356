/**
 * @generated SignedSource<<e5753247b148da64ac4479e4be3d34c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterStyle_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuMultiSelectList_itemSearch">;
  readonly " $fragmentType": "SbSharedRefineMenuFilterStyle_itemSearch";
};
export type SbSharedRefineMenuFilterStyle_itemSearch$key = {
  readonly " $data"?: SbSharedRefineMenuFilterStyle_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterStyle_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilterStyle_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuMultiSelectList_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "a10949a6fd8ba751f4c7bbdbe059d77f";

export default node;
