import { graphql, readInlineData } from 'react-relay';
import { trackAbTestV2Variant } from 'dibs-buyer-layout/exports/clientABTestV2';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';
import {
    PERSONALIZED_RERANK_TEST_NAME,
    ITEM_PRICE_IN_SELLABILITY,
    MORE_SPONSORED_ADS,
    URGENCY_SIGNALS_MW,
    ALL_URGENCY_SIGNALS_SB,
    getMoreSponsoredAdsAbTest,
} from '../../utils/abTestHelper';
import { pageTypeConstants } from '../../constants/pageTypeConstants';
import { SortMap } from '../../constants/sbConstants';
import { trackEligibleForRerankEvent } from '../../utils/tracking/searchBrowse/addPersonalizationTrackingData';

import { type SbSharedTrackingFunctions_itemSearch$key } from './__generated__/SbSharedTrackingFunctions_itemSearch.graphql';
import {
    MIN_ITEMS_COUNT,
    MOBILE_ITEMS_PER_ROW,
    RESP_ITEMS_PER_ROW,
} from '../hooks/useMergedSbAndSponsoredItems';

const itemSearchFragment = graphql`
    fragment SbSharedTrackingFunctions_itemSearch on ItemSearchQueryConnection @inline {
        trackedAbTests
        edges {
            __typename
        }
        sponsored {
            items {
                __typename
            }
            totalSponsoredAdsForPage
        }
    }
`;

type PersonalizedRerankProps = {
    pageType?: string | null;
    sortValue?: string | null;
    isEligibleForRerank?: boolean | null;
};
export const personalizedRerankTracking = ({
    pageType,
    sortValue,
    isEligibleForRerank,
}: PersonalizedRerankProps): void => {
    // This if should be the same as
    // https://github.com/1stdibs/dibs-graphql/blob/release/src/schema/inventory/itemSearch/solrQueries/generateResultQuery.ts#L228
    if (
        sortValue === SortMap.relevance ||
        sortValue === SortMap.buyPageRelevance ||
        (pageType !== pageTypeConstants.BUY && sortValue === SortMap.recommended)
    ) {
        trackAbTestV2Variant(PERSONALIZED_RERANK_TEST_NAME);
    }

    if (isEligibleForRerank) {
        trackEligibleForRerankEvent();
    }
};

export const trackActiveAbTests = (
    itemSearchRef: SbSharedTrackingFunctions_itemSearch$key
): void => {
    const itemSearch = readInlineData(itemSearchFragment, itemSearchRef);
    const trackedAbTests = (itemSearch.trackedAbTests || []).filter(filterFalsy);
    for (const abTest of trackedAbTests) {
        trackAbTestV2Variant(abTest);
    }
};

export const itemPriceInSellabilityTracking = ({
    pageType,
    sortValue,
}: {
    pageType?: string | null;
    sortValue?: string | null;
}): void => {
    if (
        (pageType === pageTypeConstants.SEARCH && sortValue === SortMap.relevance) ||
        sortValue === SortMap.recommended
    ) {
        trackAbTestV2Variant(ITEM_PRICE_IN_SELLABILITY);
    }
};

export const moreSponsoredAdsTracking = ({
    itemSearch: itemSearchRef,
    pageNumber,
    isMobile,
}: {
    itemSearch: SbSharedTrackingFunctions_itemSearch$key;
    pageNumber: number;
    isMobile: boolean | undefined;
}): void => {
    const moreSponsoredAdsAbTestVariant = getMoreSponsoredAdsAbTest();
    if (moreSponsoredAdsAbTestVariant && pageNumber >= 5 && pageNumber <= 10) {
        const itemSearch = readInlineData(itemSearchFragment, itemSearchRef);
        const { edges: items, sponsored } = itemSearch;
        const { items: sponsoredItems, totalSponsoredAdsForPage } = sponsored || {};
        const isEnoughItems = items?.length || 0 >= MIN_ITEMS_COUNT;
        const minSponsoredItems = isMobile ? MOBILE_ITEMS_PER_ROW : RESP_ITEMS_PER_ROW;
        const sponsoredAdsCount =
            moreSponsoredAdsAbTestVariant === 'control'
                ? totalSponsoredAdsForPage ?? 0
                : sponsoredItems?.length ?? 0;

        const isEnoughSponsoredItems = sponsoredAdsCount >= minSponsoredItems;

        if (isEnoughItems && isEnoughSponsoredItems) {
            trackAbTestV2Variant(MORE_SPONSORED_ADS);
        }
    }
};

export const trackUrgencySignals = (): void => {
    trackAbTestV2Variant(URGENCY_SIGNALS_MW);
    trackAbTestV2Variant(ALL_URGENCY_SIGNALS_SB);
};
