/**
 * @generated SignedSource<<954933463766e82148baed09f2578a15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuSearchResults_itemFacetSearch$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly count: number | null;
      readonly displayName: string | null;
      readonly linkReference: string | null;
      readonly urlLabel: string | null;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "SbSharedRefineMenuSearchResults_itemFacetSearch";
};
export type SbSharedRefineMenuSearchResults_itemFacetSearch$key = {
  readonly " $data"?: SbSharedRefineMenuSearchResults_itemFacetSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuSearchResults_itemFacetSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuSearchResults_itemFacetSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemFacetsQueryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FilterValueType",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "urlLabel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "linkReference",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemFacetsQueryConnection",
  "abstractKey": null
};

(node as any).hash = "19d03bf6a33af25ed8d873fd082ae1cc";

export default node;
