/**
 * @generated SignedSource<<0a9b08eb145d453a0407061b8a15f7d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useRingMeasurementType_itemSearch$data = {
  readonly appliedFilters: ReadonlyArray<{
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly urlLabel: string | null;
    } | null> | null;
  } | null> | null;
  readonly " $fragmentType": "useRingMeasurementType_itemSearch";
};
export type useRingMeasurementType_itemSearch$key = {
  readonly " $data"?: useRingMeasurementType_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"useRingMeasurementType_itemSearch">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useRingMeasurementType_itemSearch"
};

(node as any).hash = "1e89bcd6bd55556a861b7412bc9ff1c7";

export default node;
