/**
 * @generated SignedSource<<3cf8936b7ae8d39992cd378af27be95e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespRefineMenuFilterDimensions_filters$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuInputRangeDimensions_filters">;
  readonly " $fragmentType": "SbRespRefineMenuFilterDimensions_filters";
}>;
export type SbRespRefineMenuFilterDimensions_filters$key = ReadonlyArray<{
  readonly " $data"?: SbRespRefineMenuFilterDimensions_filters$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenuFilterDimensions_filters">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbRespRefineMenuFilterDimensions_filters",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuInputRangeDimensions_filters"
    }
  ],
  "type": "SearchBrowseFilter",
  "abstractKey": null
};

(node as any).hash = "da120dcca3c094f9a082023bad3bbb40";

export default node;
