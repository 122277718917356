import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, IntlContext } from 'dibs-react-intl';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { updateUriRef } from '../../../actions/filterActions';
import { formatRange, getInputRangeMinMax } from '../sbSharedRefineMenuHelpers';

// components
import { Button } from 'dibs-elements/exports/Button';
import ArrowRight from 'dibs-icons/exports/legacy/ArrowRight';
import { SbSharedLabeledInput } from '../../SbSharedLabeledInput/SbSharedLabeledInput';

// styles
import styles from './SbSharedRefineMenuInputRange.scss';

const messages = defineMessages({
    to: {
        id: 'sb.SbMobileRefineMenu.inputRange.to',
        defaultMessage: 'to',
    },
    error: {
        id: 'sb.SbMobileRefineMenu.inputRange.error',
        defaultMessage: 'Min value may not exceed max',
    },
    applyLabel: {
        id: 'sb.SbMobileRefineMenu.inputRange.applyLabel',
        defaultMessage: 'Apply range',
    },
    min: {
        id: 'sb.SbMobileRefineMenu.inputRange.min',
        defaultMessage: 'minimum',
    },
    max: {
        id: 'sb.SbMobileRefineMenu.inputRange.max',
        defaultMessage: 'maximum',
    },
});

export class SbSharedRefineMenuInputRangeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            minValue: Number(props.min) ? Math.floor(parseInt(props.min)).toString() : null,
            maxValue: Number(props.max) ? Math.ceil(parseInt(props.max)).toString() : null,
            error: false,
            showAnimatedPlaceholderMin: props.showAnimatedPlaceholder,
            showAnimatedPlaceholderMax: props.showAnimatedPlaceholder,
        };

        this.setValue = this.setValue.bind(this);
        this.applyValue = this.applyValue.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { min, max, showAnimatedPlaceholder } = this.props;
        const { minValue, maxValue } = this.state;

        const maxParsed = Math.ceil(parseInt(max)); // number | NaN
        const newMaxValue =
            typeof maxParsed === 'number' && !isNaN(maxParsed) ? maxParsed.toString() : null;
        const minParsed = Math.floor(parseInt(min)); // number | NaN
        const newMinValue =
            typeof minParsed === 'number' && !isNaN(minParsed) ? minParsed.toString() : null;
        const newState = {};
        if (prevProps.showAnimatedPlaceholder !== showAnimatedPlaceholder) {
            newState.showAnimatedPlaceholderMin = showAnimatedPlaceholder;
            newState.showAnimatedPlaceholderMax = showAnimatedPlaceholder;
            newState.maxValue = newMaxValue;
            newState.minValue = newMinValue;
        }

        if (prevProps.max !== max) {
            newState.maxValue = newMaxValue;
        }

        if (prevProps.min !== min) {
            newState.minValue = newMinValue;
        }

        // Checking both props and state validity, because both changes can invoke component update
        // depending on how range values where provided: by chosing predefined range or manually filling fields
        const error = this.isInvalidRange(min, max) || this.isInvalidRange(minValue, maxValue);
        if (prevState.error !== error) {
            newState.error = error;
        }

        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }
    }

    isInvalidRange(min, max) {
        return parseInt(min) > parseInt(max) || parseInt(max) <= 0;
    }

    setValue(name, value, e) {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        const { availableMin, availableMax, applyOnBlur, onApply, trackCustomRange } = this.props;
        let { minValue, maxValue } = this.state;
        let shouldApply;

        if (name === 'min') {
            value = parseInt(value) < parseInt(availableMin) ? availableMin : value;
            shouldApply = value !== minValue;
            minValue = value;
            this.setState({ minValue, showAnimatedPlaceholderMin: false });
        } else {
            value = parseInt(value) > parseInt(availableMax) ? availableMax : value;
            shouldApply = value !== maxValue;
            maxValue = value;
            this.setState({ maxValue, showAnimatedPlaceholderMax: false });
        }

        const isInvalidRange = this.isInvalidRange(minValue, maxValue);

        if (!isInvalidRange && shouldApply && applyOnBlur) {
            // only apply if
            // 1. there is no validation error
            // 2. the value of min or max has changed
            // 3. component is set to apply values on blur
            onApply({ minMax: [minValue, maxValue], trackCustomRange, event: e });
        }

        if (this.state.error !== isInvalidRange) {
            this.setState({ error: isInvalidRange });
        }
    }

    applyValue(e) {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        const { onApply, trackCustomRange } = this.props;
        const { minValue, maxValue, error } = this.state;

        if (!error) {
            onApply({ minMax: [minValue, maxValue], trackCustomRange, event: e });
            this.setState({ showAnimatedPlaceholderMin: false, showAnimatedPlaceholderMax: false });
        }
    }

    render() {
        const {
            labelFrom,
            labelTo,
            applyOnBlur,
            showInvalidRangeErrorMessage,
            size,
            refetchInFlight,
            ariaDescribedBy,
        } = this.props;
        const { showAnimatedPlaceholderMin, showAnimatedPlaceholderMax } = this.state;

        return (
            <Fragment>
                <div className={styles.container}>
                    <SbSharedLabeledInput
                        ariaDescribedBy={ariaDescribedBy}
                        ariaLabel={labelFrom ? undefined : this.context.formatMessage(messages.min)}
                        label={labelFrom}
                        size={size}
                        currency={this.props.currency}
                        value={this.state.minValue}
                        onBlur={(value, e) => this.setValue('min', value, e)}
                        dataTn="search-browse-currency-input-min"
                        name="price-min"
                        hasError={this.state.error}
                        showAnimatedPlaceholder={showAnimatedPlaceholderMin}
                    />
                    <div className={styles.divider}>{this.context.formatMessage(messages.to)}</div>
                    <SbSharedLabeledInput
                        ariaDescribedBy={ariaDescribedBy}
                        ariaLabel={labelTo ? undefined : this.context.formatMessage(messages.max)}
                        label={labelTo}
                        size={size}
                        currency={this.props.currency}
                        value={this.state.maxValue}
                        onBlur={(value, e) => this.setValue('max', value, e)}
                        dataTn="search-browse-currency-input-max"
                        name="price-max"
                        hasError={this.state.error}
                        showAnimatedPlaceholder={showAnimatedPlaceholderMax}
                    />
                    {!applyOnBlur && (
                        <Button
                            ariaLabel={this.context.formatMessage(messages.applyLabel)}
                            type="secondary"
                            size={size || 'large'}
                            onClick={this.applyValue}
                            className={styles.inputRangeButton}
                            isDangerousAction={this.state.error}
                            dataTn={`input-range-apply${this.state.error ? '-error' : ''}`}
                            disabled={refetchInFlight}
                        >
                            <ArrowRight className={styles.arrow} />
                        </Button>
                    )}
                </div>
                {this.state.error && showInvalidRangeErrorMessage && (
                    <div className={styles.error}>{this.context.formatMessage(messages.error)}</div>
                )}
            </Fragment>
        );
    }
}

SbSharedRefineMenuInputRangeComponent.contextType = IntlContext;

SbSharedRefineMenuInputRangeComponent.defaultProps = {
    onApply: () => {},
    applyOnBlur: false,
    showInvalidRangeErrorMessage: true,
    trackCustomRange: false,
};

SbSharedRefineMenuInputRangeComponent.propTypes = {
    itemSearch: PropTypes.object,
    onApply: PropTypes.func,
    labelTo: PropTypes.node,
    labelFrom: PropTypes.node,
    filterName: PropTypes.string,
    additionalFilter: PropTypes.string,
    statKey: PropTypes.string,
    currency: PropTypes.string,
    applyOnBlur: PropTypes.bool,
    updateOnBlur: PropTypes.bool,
    showInvalidRangeErrorMessage: PropTypes.bool,
    showAnimatedPlaceholder: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    availableMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    availableMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    refetchInFlight: PropTypes.bool,
    ariaDescribedBy: PropTypes.string,
    trackCustomRange: PropTypes.bool,
};

function mapStateToProps(state, props) {
    const { filter, filterName, statKey, itemSearch } = props;
    const { appliedFilters } = itemSearch;
    const refetchInFlight = state.filters.refetchInFlight || false;

    return {
        ...getInputRangeMinMax({ filter, filterName, statKey, appliedFilters }),
        refetchInFlight,
    };
}

function mapDispatchToProps(dispatch, { filterName, filter, additionalFilter, statKey }) {
    return {
        onApply({ minMax, trackCustomRange, event }) {
            let uriRef = formatRange(filter?.values?.[0]?.linkReference || '', minMax);
            // Add `currency` query param for `price` and `measurement-unit` for dimensions params
            if (statKey && additionalFilter) {
                uriRef = uriRef.replace(`{${additionalFilter}}`, statKey.toLowerCase());
            }
            dispatch(
                updateUriRef({
                    filterName,
                    uriRef,
                    ga: trackCustomRange ? { label: `custom ${filterName} range` } : {},
                    event,
                })
            );
        },
    };
}

export const SbSharedRefineMenuInputRange = createFragmentContainer(
    connect(mapStateToProps, mapDispatchToProps)(SbSharedRefineMenuInputRangeComponent),
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuInputRange_itemSearch on ItemSearchQueryConnection {
                appliedFilters {
                    name
                    values {
                        urlLabel
                    }
                }
            }
        `,
    }
);
