import { useState, useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import { localStorage } from 'dibs-browser-storage';
import { getSupportedMeasurementUnit, type MeasurementUnit } from 'dibs-intl/exports/units';
import { getFilterValue } from './SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { useSbSelector } from '../reducers/useSbSelector';

// constants
import { LOCAL_STORAGE_KEY } from '../constants/measurementUnits';
import { MEASUREMENT_UNIT } from './SbSharedRefineMenu/sbSharedRefineMenuConstants';

import { type useMeasurementUnit_itemSearch$key } from './__generated__/useMeasurementUnit_itemSearch.graphql';
import { type useMeasurementUnit_user$key } from './__generated__/useMeasurementUnit_user.graphql';

const itemSearchFragment = graphql`
    fragment useMeasurementUnit_itemSearch on ItemSearchQueryConnection {
        appliedFilters {
            name
            values {
                urlLabel
            }
        }
    }
`;
const userFragment = graphql`
    fragment useMeasurementUnit_user on User {
        preferences {
            measurementUnit
        }
    }
`;

export const useMeasurementUnit = ({
    itemSearch: itemSearchRef,
    user: userRef,
}: {
    itemSearch: useMeasurementUnit_itemSearch$key;
    user: useMeasurementUnit_user$key;
}): [MeasurementUnit] => {
    const defaultMeasurementUnit = useSbSelector(state => state.measurementUnit);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const user = useFragment(userFragment, userRef);

    const userValue = user?.preferences?.measurementUnit || '';
    const appliedFilterValue = (
        getFilterValue(itemSearch?.appliedFilters, MEASUREMENT_UNIT)?.urlLabel || ''
    ).toUpperCase();
    /**
     * Same order, just without localStorage
     */
    const initialMeasurementUnit = getSupportedMeasurementUnit(
        appliedFilterValue || userValue || defaultMeasurementUnit
    );
    const [measurementUnit, setMeasurementUnit] = useState(initialMeasurementUnit);

    useEffect(() => {
        setMeasurementUnit(() => {
            const localStorageValue = localStorage.getItem(LOCAL_STORAGE_KEY) as string;

            /**
             * If localstorage value is set to something different than the user value
             * dimensions will flash, this check will set the localstorage value to the user value
             * to prevent the dimension flashing on next refresh
             */
            if (userValue && userValue !== localStorageValue) {
                localStorage.setItem(LOCAL_STORAGE_KEY, userValue);
            }

            /**
             * Set the measurement unit with the following order of importance:
             *  1. if it is an applied filter
             *  2. to the users preference
             *  3. to what is stored in localStorage
             *  4. location default
             */
            const newMeasurementUnit =
                appliedFilterValue || userValue || localStorageValue || defaultMeasurementUnit;
            return getSupportedMeasurementUnit(newMeasurementUnit);
        });
    }, [userValue, appliedFilterValue, defaultMeasurementUnit]);

    return [measurementUnit];
};
