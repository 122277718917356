import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { type SbSharedRefineMenuFilterFramingOld_itemSearch$key } from './__generated__/SbSharedRefineMenuFilterFramingOld_itemSearch.graphql';

import { defineMessages } from 'dibs-react-intl';
import {
    FRAMING_OPTION_AVAILABLE,
    FRAME_INCLUDED,
} from '../../SbSharedRefineMenu/sbSharedRefineMenuConstants';

// components
import { SbSharedRefineMenuFramingInput } from '../SbSharedRefineMenuFramingInput/SbSharedRefineMenuFramingInput';

const messages = defineMessages({
    frameIncludedTitle: {
        id: 'sb.SbSharedRefineMenuFilterFrameIncludedOld.title',
        defaultMessage: 'Frame Included',
    },
    framingOptionsAvailableTitle: {
        id: 'sb.SbSharedRefineMenuFilterFramingOptionAvailableOld.title',
        defaultMessage: 'Framing Options Available',
    },
});

type Props = {
    itemSearch: SbSharedRefineMenuFilterFramingOld_itemSearch$key;
};

export const SbSharedRefineMenuFilterFramingOld: FC<Props> = ({ itemSearch: itemSearchRef }) => {
    const itemSearch = useFragment(
        graphql`
            fragment SbSharedRefineMenuFilterFramingOld_itemSearch on ItemSearchQueryConnection {
                ...SbSharedRefineMenuFramingInput_itemSearch
            }
        `,
        itemSearchRef
    );

    return (
        <>
            <SbSharedRefineMenuFramingInput
                itemSearch={itemSearch}
                filterName={FRAME_INCLUDED}
                title={messages.frameIncludedTitle}
            />
            <SbSharedRefineMenuFramingInput
                itemSearch={itemSearch}
                filterName={FRAMING_OPTION_AVAILABLE}
                title={messages.framingOptionsAvailableTitle}
            />
        </>
    );
};
