import { type MutableRefObject, useReducer, useEffect, useRef, type Reducer } from 'react';

const VIEW_MORE = 'viewMore';
type State = {
    wasExpanded: boolean;
    visibleCount: number;
};
type Action = { type: typeof VIEW_MORE };

export const useViewMore = <T extends HTMLElement>({
    totalCount,
    revealCount,
    initialCount,
}: {
    totalCount: number;
    revealCount: number;
    initialCount: number;
}): {
    viewMoreEl: MutableRefObject<T | null>;
    dispatch: () => void;
    wasExpanded: boolean;
    visibleCount: number;
} => {
    const reducer: Reducer<State, Action> = (state, action) => {
        const wasExpanded = true;
        switch (action.type) {
            case VIEW_MORE:
                if (state.visibleCount >= totalCount) {
                    return {
                        wasExpanded,
                        visibleCount: initialCount,
                    };
                } else {
                    return {
                        wasExpanded,
                        visibleCount: state.visibleCount + revealCount,
                    };
                }
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, {
        wasExpanded: false,
        visibleCount: initialCount,
    });

    const viewMoreEl: MutableRefObject<T | null> = useRef(null);

    useEffect(() => {
        if (state.wasExpanded && viewMoreEl.current) {
            // There is incosistent behaviour among browsers about if element is focused or not after being clicked.
            // For accessibility reasons we need to focus on button after it is being clicked by mouse as well as by keypress.
            // Safari does not support focus() with preventScroll option, so it scrolls together with View More button.
            // Need workaround to keep scroll position fixed before and after click.
            const currentPosition = window.scrollY;
            viewMoreEl.current.focus();
            window.scrollTo(0, currentPosition);
        }
    }, [state.visibleCount, state.wasExpanded]);

    return {
        viewMoreEl,
        dispatch: () => dispatch({ type: VIEW_MORE }),
        wasExpanded: state.wasExpanded,
        visibleCount: state.visibleCount,
    };
};
