import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import Helmet from 'react-helmet';

import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { type SharedHeadLinkTags_metadata$key } from './__generated__/SharedHeadLinkTags_metadata.graphql';
import { useIntl } from 'dibs-react-intl';
import { handleLocaleUrl } from 'dibs-intl/exports/urls';
import { addTrailingSlash, addLeadingSlash } from '../../utils/uriUtils';

const metadataFragment = graphql`
    fragment SharedHeadLinkTags_metadata on MetaData {
        canonical
        disableIndexing
        next
        prev
        languageAnnotations {
            href
            hrefLang
        }
    }
`;

type Props = {
    metadata: SharedHeadLinkTags_metadata$key;
};

export const SharedHeadLinkTags: FC<Props> = ({ metadata: metadataRef }) => {
    const metadata = useFragment(metadataFragment, metadataRef);
    const intl = useIntl();
    const { localeOrigin } = useServerVarsContext();

    const linkTagsArray = [];

    if (!metadata.disableIndexing) {
        const { canonical, prev, next, languageAnnotations } = metadata;

        if (canonical) {
            linkTagsArray.push({
                rel: 'canonical',
                href: addTrailingSlash(
                    `${localeOrigin}${handleLocaleUrl(addLeadingSlash(canonical), intl.locale)}`
                ),
            });
        }

        if (prev) {
            linkTagsArray.push({
                rel: 'prev',
                href: addTrailingSlash(
                    `${localeOrigin}${handleLocaleUrl(addLeadingSlash(prev), intl.locale)}`
                ),
            });
        }

        if (next) {
            linkTagsArray.push({
                rel: 'next',
                href: addTrailingSlash(
                    `${localeOrigin}${handleLocaleUrl(addLeadingSlash(next), intl.locale)}`
                ),
            });
        }

        if (languageAnnotations) {
            languageAnnotations.forEach(languageAnnotation => {
                const { href: path, hrefLang: hreflang } = languageAnnotation || {};
                if (path && hreflang) {
                    linkTagsArray.push({
                        rel: 'alternate',
                        href: `${localeOrigin}${path}`,
                        hreflang,
                    });
                }
            });
        }
    }

    return <Helmet link={linkTagsArray} />;
};
