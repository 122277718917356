/**
 * @generated SignedSource<<ab4fadf141cbc57ee36ad1b9f7ebdf89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuTypeaheadContainer_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuTypeahead_viewer">;
  readonly " $fragmentType": "SbSharedRefineMenuTypeaheadContainer_viewer";
};
export type SbSharedRefineMenuTypeaheadContainer_viewer$key = {
  readonly " $data"?: SbSharedRefineMenuTypeaheadContainer_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuTypeaheadContainer_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuTypeaheadContainer_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuTypeahead_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "11a8387da621d8ab5f0377a51e8d4ebc";

export default node;
