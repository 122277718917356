/**
 * @generated SignedSource<<def92b919107534474acc06913cf272c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespRefineMenuFilterCategory_filters$data = ReadonlyArray<{
  readonly name: string | null;
  readonly values: ReadonlyArray<{
    readonly count: number | null;
    readonly displayName: string | null;
    readonly linkReference: string | null;
    readonly linkable: boolean | null;
    readonly urlLabel: string | null;
  } | null> | null;
  readonly " $fragmentType": "SbRespRefineMenuFilterCategory_filters";
}>;
export type SbRespRefineMenuFilterCategory_filters$key = ReadonlyArray<{
  readonly " $data"?: SbRespRefineMenuFilterCategory_filters$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenuFilterCategory_filters">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbRespRefineMenuFilterCategory_filters",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseFilterValue",
      "kind": "LinkedField",
      "name": "values",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkReference",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkable",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SearchBrowseFilter",
  "abstractKey": null
};

(node as any).hash = "c851e89c999260ca6e595c7390c1c499";

export default node;
