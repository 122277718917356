/**
 * @generated SignedSource<<ea706467d53af833bd256ae5a7b5f194>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilterRingSize_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuRingSize_itemSearch">;
  readonly " $fragmentType": "SbSharedRefineMenuFilterRingSize_itemSearch";
};
export type SbSharedRefineMenuFilterRingSize_itemSearch$key = {
  readonly " $data"?: SbSharedRefineMenuFilterRingSize_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilterRingSize_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilterRingSize_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuRingSize_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "ddd880ebaee3accaf11695f2aab00784";

export default node;
