/**
 * @generated SignedSource<<eb76992eab9ffe348fd62f8978d527ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedHeadLinkTags_metadata$data = {
  readonly canonical: string | null;
  readonly disableIndexing: boolean | null;
  readonly languageAnnotations: ReadonlyArray<{
    readonly href: string | null;
    readonly hrefLang: string | null;
  } | null> | null;
  readonly next: string | null;
  readonly prev: string | null;
  readonly " $fragmentType": "SharedHeadLinkTags_metadata";
};
export type SharedHeadLinkTags_metadata$key = {
  readonly " $data"?: SharedHeadLinkTags_metadata$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedHeadLinkTags_metadata">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedHeadLinkTags_metadata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canonical",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disableIndexing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "next",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prev",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LanguageAnnotations",
      "kind": "LinkedField",
      "name": "languageAnnotations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "href",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hrefLang",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MetaData",
  "abstractKey": null
};

(node as any).hash = "b3ae4569c6a70652a2ac1f69734ca02d";

export default node;
