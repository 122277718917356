/**
 * @generated SignedSource<<7386b146c9e078076a4736961098ab8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespRefineMenuFilterStoneCut_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuTypeaheadContainer_viewer">;
  readonly " $fragmentType": "SbRespRefineMenuFilterStoneCut_viewer";
};
export type SbRespRefineMenuFilterStoneCut_viewer$key = {
  readonly " $data"?: SbRespRefineMenuFilterStoneCut_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenuFilterStoneCut_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespRefineMenuFilterStoneCut_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuTypeaheadContainer_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "fab7eb5574a476fe0f59bb7094fd7d77";

export default node;
